import styled from 'styled-components'

export const StyledBanner = styled.div`
  ${({ theme }) => `
    * {
      box-sizing: border-box
    }


    .parentBanner {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      .bannerImg {
        flex-basis: 20%;
        img{
          width: 100%;
          margin-top: 90px;
          border: white 12px solid;
          box-shadow: ${theme['box-shadow']}
        }
      }
      p {
        padding: ${theme['padding-medium']};
        padding-top: ${theme['padding-zero']};
        margin-top: ${theme['margin-zero']};

        flex-basis: 80%;
      }
      a {
          color: ${theme['main-color']};
      }

      width: 72em;
      height: 30em;
      background: gray;
      margin: -3em auto 1em auto;
      position: relative;
      line-height: 2em;
      font-family: monospace;
      padding: 2em 1em 0;
      background: linear-gradient(${theme['stripe-dark']} 1%, white 1%, white 50%, ${theme['stripe-dark']} 50%, ${theme['stripe']} 52%);
      color: rgb(20,20,50);
      background-size: 100% 4em;
      filter: drop-shadow(2px 2px 5px rgba(black, .25));
      z-index: 2;
      -webkit-animation: print 3s cubic-bezier(.75,.8,0,-0.5);
      &::before, &::after {
        content: '';
        position: absolute;
        top: 0
        width: 2em;
        height: inherit;
        background: salmon;
        background: radial-gradient(transparent 24%, white 24%) 0 0;
        background-size: 2em 2em;
      }
        
      &::before {
        left: -2em;
        border-right: 1px dashed rgba(${theme['stripe']}, .7);
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &::after {
        right: -2em;
        border-left: 1px dashed rgba(${theme['stripe']}, .7);
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      @media (max-width: 1250px) and (min-width: 850px) {
        width: 50em;
        height: 34em;
      }

      @media (max-width: 849px) and (min-width: 560px) {
        width: 32em;
        height: 50em;
        .bannerImg{
          img{
            margin-top: 0.5em;
          }
          flex-basis: 30%;
        }
        p{
          padding: 0px;
          flex-basis:100%;
        }

      }

      @media (max-width: 559px) and (min-width: 0px) {
        width: 20em;
        height: 72em;
   
        .bannerImg {
          img{
            margin-top: 2em;
          }
          flex-basis: 50%;
        }
        p{
          
          padding: 0px;
          flex-basis:100%;
        }
      }
    }
    `};
`
