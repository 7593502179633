import React from 'react'
import { StyledBanner } from '../style/components/banner'
import images from '../images/about/stephen.png'

const Banner = () => (
  <StyledBanner>
    <div className="parentBanner">
      <div className="bannerImg">
        <img src={images} alt="Stephen" />
      </div>
      <p>
        <b>Hi, I'm Stephen!</b>
        <br />
        Deploying a full stack React website should be straightforward, but I’ve
        found that it often can turn into a nightmare trying to set up all of
        the servers, databases, and configurations correctly so that everything
        works.
        <br />
        <br />
        Code Mochi will make this easier with videos and code that use best
        practices to build and structure your app so that deployments will be
        automated and tested from the start so that you can avoid common
        pitfalls.
        <br />
        <br />
        These blog posts will always be free so please share and tweet us{' '}
        <a
          href="https://twitter.com/intent/tweet?text=@codemochi"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>@codemochi</b>
        </a>{' '}
        to get the word out!
        <br />
        <br />
        With 💜, ☀️, and 🦄s,
        <br />
        Stephen
      </p>
    </div>
  </StyledBanner>
)

export default Banner
