import React from 'react'
import { StyledCoursesList, StyledCard } from '../style/components/course-list'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
// import TagList from './tag-list'

const Courses = [
  {
    title: 'Source Compare',
    description:
      'Collaboratively version control your images just like your code.',
    imageName: 'courses/source-compare.png',
    link: 'https://www.sourcecompare.com',
    tags: ['saas', 'nextjs', 'aws'],
    new: false,
    type: 'App',
    filename: 'courses/source-compare.png',
  },
  {
    title: 'End to End React with Prisma 2',
    description:
      'Learn to build a fully-featured social RSS reader app with Next.js and Prisma 2.',
    imageName: 'courses/end-to-end-react.jpg',
    link: 'https://courses.codemochi.com/end-to-end-react-with-prisma-2',
    tags: ['prisma', 'react', 'nextjs', 'tailwind', 'auth0'],
    new: false,
    type: 'Course',
    filename: 'courses/end-to-end-react.jpg',
  },
  {
    title: 'Frontend Serverless with React and GraphQL',
    description:
      'A training course for building scalable, production-grade frontend React applications.',
    imageName: 'courses/frontend-serverless-v20.png',
    link:
      'https://courses.codemochi.com/frontend-serverless-with-react-and-graphql',
    tags: ['typescript', 'react', 'nextjs', 'now', 'graphcms'],
    new: false,
    type: 'Course',
    filename: 'courses/frontend-serverless-v20.png',
  },
]

const Card = ({ course }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: { relativePath: { regex: "/courses/" } }) {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const image = data.images.edges.find(n => {
          return n.node.relativePath.includes(course.filename)
        })
        if (!image) {
          return null
        }
        return (
          <div className="inner-card">
            {course.new ? <span className="new">New!</span> : null}
            <Img fluid={image.node.childImageSharp.fluid} alt={course.title} />
            <p>{course.description}</p>
            {course.type ? (
              <div className={course.type === 'App' ? 'app' : 'course'}>
                {course.type}
              </div>
            ) : null}
          </div>
        )
      }}
    />
  )
}

const LinkWrapper = ({ link, children }) => <a href={link}>{children}</a>

const CourseList = () => {
  return (
    <StyledCoursesList>
      {Courses.map((course, i) => (
        <div key={i}>
          <StyledCard className="styled-card">
            {course.link ? (
              <LinkWrapper link={course.link}>
                <Card course={course} />
              </LinkWrapper>
            ) : (
              <Card course={course} />
            )}
            {/* <TagList tags={course.tags} useSmallTags={true} /> */}
          </StyledCard>
        </div>
      ))}
    </StyledCoursesList>
  )
}

export default CourseList
